import React from "react";
import Layout from "../components/Layout/Layout";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  root: {
    listStyleType: "none",
    margin: 0
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "28px"
  }
}));

const RavidassJayantiDatesPage = () => {
  const classes = useStyles();
  return (
    <Layout>
      <SEO
        title="Guru Ravidass birth anniversary"
        description=" Guru Ravidas Jayanti takes place on the full moon of the month of Magh
        on the Hindu Calendar. On the Gregorian Calendar, it normally falls
        sometime in February. "
      />
      <Paper
        style={{
          padding: "16px"
        }}
      >
        <h1 className={classes.title}>Guru Ravidass birth anniversary</h1>
        <p>
          The birth anniversary of the Shri Guru Ravidass Maharaj Ji is a
          popular holiday in northern India.
        </p>
        <p>
          Guru Ravidas Jayanti takes place on the full moon of the month of Magh
          on the Hindu Calendar. On the Gregorian Calendar, it normally falls
          sometime in February. The festival of Guru Ravidas Jayanti is of
          utmost significance for those who entrust in the beliefs and
          ideologies of Ravidas religion. It is the most significant day of the
          year.
        </p>
        <p>
          Shri Guru Ravidass Maharaj Ji was a famous mystical poet and song
          writer who lived in the 1400’s and 1500’s. He had a huge influence on
          the “Bhakti Movement”, which was a “spiritual devotional” movement
          within Hinduism that later morphed into the new Sikh religion.
        </p>
        <p>
          However, his teachings also led to the founding of the Ravidassia
          religion in the 21st Century, and it is mostly followers of Ravidassia
          who celebrate Guru Ravidas Jayanti and take it off work each year.
          Devotees will perform various rituals, chant his songs and poems,
          march in parades while carrying a big picture of Guru Ravidas, and
          bath in the Ganges River. Some will also go on pilgrimage to a temple
          dedicated to Ravidas and offer their prayers.
        </p>
        <section>
          <h3>Rituals And Celebrations – Guru Ravidas Jayanti</h3>
          <p>
            All gurudwaras across the country make special arrangements on this
            special occasion. They are adorned with colorful lights and special
            prayer meetings in adoration of Guru Ravidas are put in place.
            Amritbani, which is the holy book of Ravidassia religion, is read.
            Moreover, the official symbol of Ravidassia religion is changed in a
            special ceremony. The Nag Kirtan is the prime feature of the
            festival. A few people dressed up as Guru Ravidas and his
            supporters.
          </p>
        </section>{" "}
      </Paper>
    </Layout>
  );
};

export default RavidassJayantiDatesPage;
